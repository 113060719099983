import React, { useEffect, useState } from 'react';
import { Toast } from 'antd-mobile';
import DocumentMeta from 'react-document-meta';
import request from '../../shared/request';
import Native from '../../shared/native';
import If from '../components/if';
import Link from '../components/link';

const BTN_DESC = {
  fetch: '海报生成中...',
  unsupport: 'Malus版本过低请升级后再分享好友',
  ok: '立即分享赢积分得现金',
};

const Share = () => {
  const [modal, setModal] = useState(false);
  const [affiliate, setAffiliate] = useState({});
  const [poster, setPoster] = useState();
  const [channels, setChannels] = useState({});
  const [uid, setUid] = useState('**');
  // const [downloaded, setDownloaded] = useState(false);
  const unsupport = Native.canShare();
  const [btn, setBtn] = useState(!unsupport ? 'unsupport' : 'fetch');
  const meta = {
    title: '分享送会员',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };

  const init = async () => {
    const { code, data, message } = await request.call('getCreditOverview');
    if (code === 0) {
      setAffiliate(data.affiliate);
      setUid(data.uid);
    } else {
      Toast.fail(message);
    }
  };
  useEffect(() => {
    init();
    getPoster();
  }, []);

  const getPoster = async () => {
    const { code, data, message } = await request.call('getSharePoster');
    if (btn !== 'unsupport') {
      setBtn('ok');
    }
    if (code === 0) {
      setPoster(data.image);
      setChannels(data.channels);
    } else {
      Toast.fail(message);
    }
  };
  const sendWechatSession = async () => {
    Native.shareImage(poster, 'session');
  };
  const download = async () => {
    // if (Native.device() === 'ios' && downloaded) {
    //   return Toast.fail('请勿重复下载');
    // }

    Native.shareImage(poster, 'saveImage');
    // setDownloaded(true);
    Toast.info('图片已下载');
  };
  const shareWechatFriend = async () => {
    Native.shareImage(poster, 'timeline');
  };
  const shareLink = async channel => {
    const link = channels[channel];
    Native.open(link);
  };

  return (
    <DocumentMeta {...meta}>
      <div className="content-wrapper page-affiliate">
        <If condition={modal}>
          <div className="share-modal">
            <div className="share-content">
              <div className="channels">
                <div className="channel" onClick={sendWechatSession}>
                  <span className="wechat" />
                  <span className="name">微信好友</span>
                </div>
                <div className="channel" onClick={shareWechatFriend}>
                  <span className="friend" />
                  <span className="name">朋友圈</span>
                </div>
                <div className="channel" onClick={e => shareLink('weibo')}>
                  <span className="weibo" />
                  <span className="name">微博</span>
                </div>
                <div className="channel" onClick={e => shareLink('twitter')}>
                  <span className="twitter" />
                  <span className="name">Twitter</span>
                </div>
                <div className="channel" onClick={e => shareLink('facebook')}>
                  <span className="facebook" />
                  <span className="name">Facebook</span>
                </div>
                <div className="channel" onClick={download}>
                  <span className="download" />
                  <span className="name">保存图片</span>
                </div>
              </div>
              <btn className="close-btn" onClick={e => setModal(false)}>
                取消分享
              </btn>
            </div>
          </div>
        </If>

        <div className="hero">
          <div className="banner">
            <div className="left">
              <h3>邀请好友得现金</h3>
              <div className="des">
                <p>
                  注册成功得 <strong>50积分</strong>{' '}
                </p>
                <p>
                  购买成功最高 <strong>返现 100%</strong>{' '}
                </p>
              </div>
            </div>
            <div className="right" />
          </div>

          <div className="progress">
            <div className="wrapper">
              <div className="step">分享好友链接或图</div>
              <div className="step">好友注册得积分</div>
              <div className="step">好友购买得现金</div>
            </div>
          </div>
        </div>

        <If condition={uid}>
          <div className="users">ID{uid} 刚通过邀请好友获得了 50 积分</div>
        </If>
        <div className="card">
          <Link className="item" to="/app/credits-detail">
            <div className="des">已邀请注册</div>
            <div className="bottom">
              <span className="nb nbfont">{affiliate.count}</span>
              <small>人</small>
              <i className="ri-arrow-right-s-line" />
            </div>
          </Link>
          <div className="item">
            <div className="des">可提现奖金</div>
            <div className="bottom">
              <span className="nb nbfont">{affiliate.balance}</span>
              <small>元</small>
            </div>
          </div>
          <div className="item">
            <div className="des">已提现奖金</div>
            <div className="bottom">
              <span className="nb nbfont">{affiliate.withdrawed}</span>
              <small>元</small>
            </div>
          </div>
        </div>

        <div className="rule-content">
          <span className="title">规则说明</span>
          <ul>
            <li className="rule">
              活动期间每成功邀请 1 位新用户注册并激活成功，即可获得 50
              积分奖励，奖励不设上限。
            </li>
            <li className="rule">
              已邀请用户成功购买或续费 Malus 套餐会员，都将获得相应的现金奖励。
            </li>
            <li>
              如果被推荐者在正式付费 30
              天内发生退款，将无法享受该订单的现金奖励。
            </li>
          </ul>
          <span className="title">现金返现比例</span>
          <table>
            <tr>
              <th>购买时长</th>
              <th>新购用户返现率</th>
              <th>续费用户返现率</th>
            </tr>
            <tr>
              <td>30天</td>
              <td>100%</td>
              <td>50%</td>
            </tr>
            <tr>
              <td>90天</td>
              <td>40%</td>
              <td>30%</td>
            </tr>
            <tr>
              <td>180天</td>
              <td>30%</td>
              <td>20%</td>
            </tr>
            <tr>
              <td>360天</td>
              <td>20%</td>
              <td>10%</td>
            </tr>
            <tr>
              <td>720天</td>
              <td>10%</td>
              <td>8%</td>
            </tr>
            <tr>
              <td>1080天</td>
              <td>8%</td>
              <td>5%</td>
            </tr>
          </table>
          <span className="title">如何提现？</span>
          <ul>
            <li>
              用户购买明细及提现方法请使用手机或电脑浏览器登录访问
              https://getmalus.com/dashboard/affiliate
            </li>
            <li>支持提现到支付宝和 Paypal。</li>
            <li>提成金额累计到 20美金 才可提现。</li>
            <li>
              一个自然月可以提现一次，提现会通过人工审核，审核周期为 2
              个工作日。
            </li>
          </ul>
        </div>

        <div className="float-btn">
          <div
            className={`btn ${btn !== 'ok' ? 'btn-disabled' : ''}`}
            onClick={e => btn === 'ok' && setModal(true)}>
            {BTN_DESC[btn]}
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
};
export default Share;
