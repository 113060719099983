import React, { useEffect, useState } from 'react';
import { Toast } from 'antd-mobile';
import DocumentMeta from 'react-document-meta';
import request from '../../shared/request';
import If from '../components/if';

const Share = props => {
  const [product, setProduct] = useState({
    cover: '',
    name: '加载中...',
    points: '--',
    rules: [],
    placeholder: '',
  });
  const meta = {
    title: '分享送会员',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };
  const [loading, setLoading] = useState(false);
  const [initing, setIniting] = useState(true);
  const [chargeAccount, setChargeAccount] = useState();
  const init = async () => {
    let id;
    try {
      const urlSearch = new URLSearchParams(props.location.search);
      id = urlSearch.get('id');
    } catch (e) {
      // noop
    }
    const { code, data, message } = await request.call('getCreditGoods');
    setIniting(false);
    if (code === 0) {
      const matched = data.find(v => v.id === id);
      if (matched.rules) {
        matched.rules = matched.rules.split('\n');
      }
      console.log(matched);
      if (matched) {
        setProduct(matched);
      }
    } else {
      Toast.fail(message);
    }
  };

  const redeem = async () => {
    if (loading) {
      return;
    }
    const body = {
      id: product.id,
      chargeAccount,
    };
    setLoading(true);
    const { code, message } = await request.call('redeemCredit', {
      body,
    });
    setLoading(false);
    if (code === 0) {
      Toast.success('兑换成功');
      props.history.replace('/app/credits');
    } else {
      Toast.fail(message);
    }
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <DocumentMeta {...meta}>
      <div
        className={`${initing ? 'initing' : ''} page-redeem content-wrapper`}>
        <div className="card">
          <img src={product.cover} className="banner" alt="" />
          <div className="info">
            <div className="title">{product.name}</div>
            <div className="price">
              <i className="ri-copper-diamond-fill" />
              {product.points}积分
            </div>
          </div>
        </div>
        <If condition={product.gid}>
          <div className="inputs">
            <input
              type="text"
              onChange={e => setChargeAccount(e.target.value)}
              value={chargeAccount}
              placeholder={product.placeholder}
            />
          </div>
        </If>

        <div className="rule-content">
          <span className="title">使用说明</span>
          <ul>
            {product.rules.map(rule => (
              <li className="rule">{rule}</li>
            ))}
          </ul>
        </div>
        <div className="float-btn">
          <div onClick={redeem} className={loading ? 'loading' : 'btn'}>
            {' '}
            立即兑换
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
};
export default Share;
