import React, { useEffect, useState } from 'react';
import native from '../../shared/native';
import request from '../../shared/request';
import If from '../components/if';
import DocumentMeta from 'react-document-meta';
import { getOSVersion, stdd, avg, nowTime, sleep } from './utils';
import { Toast } from 'antd-mobile';

let controller;
let loop = false;
let process = 0;
let packages = {};

const READY = 0;
const LOADING = 1;
const TESTING = 2;

if (window.MacGap) {
  window.MacGap.Window.resize(375, 600);
}

const Check = () => {
  const meta = {
    title: '网络检测',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };
  const [result, setResult] = useState(null);
  const [status, setStatus] = useState(READY);
  const [ingressNode, setIngressNode] = useState('--');
  const [egressNode, setEgressNode] = useState('--');
  const [ingressConnected, setIngressConnected] = useState(true);
  const [egressConnected, setEgressConnected] = useState(true);
  const [ip, setIp] = useState('--');
  const [account, setAccount] = useState('--');
  const [time, setTime] = useState('--');
  const [device, setDevice] = useState('--');
  const check = async () => {
    if (status !== READY) {
      return;
    }
    setStatus(LOADING);

    let response = await request.call('getProxyIngressDetail', {
      API_ENDPOINT: 'https://ingress.getmalus.cn/api',
    });
    if (response.code === 0) {
      setIngressConnected(!!response.data.connected);
      const ingressServer = response.data.server;
      setIngressNode(`${ingressServer.name}(${ingressServer.id})`);
    }
    response = await request.call('getProxyEgressDetail', {
      API_ENDPOINT: 'https://back.getmalus.cn/api',
    });
    if (response.code === 0) {
      setEgressConnected(!!response.data.connected);
      const egressServer = response.data.server;
      setEgressNode(`${egressServer.name}(${egressServer.id})`);
    }
    try {
      let user = JSON.parse(window.localStorage.getItem('login'));
      response = await request.call('getUserStatus');
      if (response.code === 0) {
        window.localStorage.setItem('login', JSON.stringify(response.data));
        user = response.data;
      }
      setAccount(user.email || user.phone || user.wechatName);
    } catch (error) {
      // noop
    }
    const { os, version } = getOSVersion();
    setDevice(`${os} ${version}`);
    setTime(nowTime());
    response = await request.call('getProxyClientDetail');
    if (response.code === 0) {
      setIp(`${response.data.ip}(${response.data.location})`);
      // if (true) {
      if (response.data.canSpeedtest) {
        await speedtest();
      } else {
        setStatus(READY);
      }
    }
  };

  const btnStatus = () => {
    const statusMap = {
      [READY]: '重新检测',
      [LOADING]: '检测中...',
      [TESTING]: '测速中...',
    };
    return statusMap[status];
  };

  const ping = ({ socket, interval }) => {
    return new Promise(resolve => {
      setTimeout(() => {
        socket.send(Date.now());
        socket.addEventListener('message', function (event) {
          const duration = Date.now() - event.data;
          resolve(duration);
        });
      }, interval);
    });
  };

  const awaitConnectSuccess = socket => {
    return new Promise(resolve => {
      socket.addEventListener('open', function (event) {
        resolve(true);
      });
    });
  };

  const execPingPlan = async data => {
    const { pingPlan, server } = data;
    const { auth, host, port } = server;
    const accessToken = btoa(`${auth.username}:${auth.password}`);
    const socketURL = `wss://${host}:${port}/ping?accessToken=${accessToken}`;
    const socket = new WebSocket(socketURL);
    await awaitConnectSuccess(socket);
    const { count, interval } = pingPlan;
    const durations = [];
    for (let i = 0; i <= count; i++) {
      const duration = await ping({ socket, interval });
      durations.push(duration);
    }
    return durations;
  };

  const downloadXHR = (url, options = {}, config = {}) => {
    const { concurrency, end } = config;
    if (process > concurrency) {
      return false;
    }
    process = process + 1;
    const pid = Date.now();
    packages[pid] = 0;

    return new Promise(resolve => {
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url);
      if (options.headers) {
        Object.keys(options.headers).map(k => {
          xhr.setRequestHeader(k, options.headers[k]);
        });
      }
      xhr.responseType = 'blob';
      var updateProgress = e => {
        packages[pid] = e.loaded;
        if (end <= Date.now()) {
          xhr.abort();
        }
        if (e.loaded >= e.total) {
          process = process - 1;
          resolve(e.loaded);
        }
      };
      xhr.addEventListener('progress', updateProgress);
      xhr.send();
    });
  };

  const download = async (url, options = {}, config = {}) => {
    const { concurrency, end } = config;
    if (process >= concurrency) {
      return false;
    }
    process = process + 1;
    const pid = Date.now();
    packages[pid] = 0;
    controller = new AbortController();
    const init = {
      signal: controller.signal,
      ...options,
    };
    await fetch(url, init)
      .then(response => {
        const reader = response.body.getReader();
        const stream = new ReadableStream({
          start: controller => {
            const push = () => {
              reader
                .read()
                .then(({ done, value }) => {
                  if (done) {
                    controller.close();
                    return;
                  }
                  if (end >= Date.now()) {
                    packages[pid] = value.byteLength + packages[pid];
                  }

                  controller.enqueue(value);
                  push();
                })
                .catch(() => {
                  // noop
                });
            };
            push();
          },
        });
        return new Response(stream);
      })
      .then(response => response.blob());
    process = process - 1;
  };

  const execDownloadPlan = async data => {
    packages = {};
    const { speedPlan, server } = data;
    const { auth, host, port } = server;
    const { concurrency, duration, interval, size } = speedPlan;
    const accessToken = btoa(`${auth.username}:${auth.password}`);
    const downloadURL = `https://${host}:${port}/download?size=${size}`;
    // const downloadURL = `https://download.getmalus.com/uploads/malus_android_4_6_1.apk`;
    const Authorization = `Basic ${accessToken}`;
    const headers = {
      Authorization,
    };
    loop = true;
    const end = Date.now() + duration;
    while (loop) {
      downloadXHR(downloadURL, { headers }, { concurrency, end });
      await sleep(interval);
      if (end <= Date.now()) {
        loop = false;
      }
    }
    process = 0;
    const values = Object.values(packages);
    const total = values.reduce((a, b) => a + b, 0);
    return total / (1024 * 1024) / (duration / 1000);
  };

  const speedtest = async () => {
    setResult(null);
    setStatus(TESTING);
    const { code, data, message } = await request.call('getSpeedTestPlan');
    if (code !== 0) {
      setStatus(READY);
      return Toast.fail(message || 'failed');
    }
    const { adInfo, adType, adToken } = data;
    const response = await native.getAdInfo(adType, adInfo, adToken);
    // const response = {
    //   code: 0,
    //   rawData: data.rawData,
    // };
    if (response.code !== 0) {
      setStatus(READY);
      return Toast.fail(response.message || 'ENV UNSUPPORT');
    }
    const durations = await execPingPlan(response.rawData);
    const lag = avg(durations);
    const jitter = stdd(durations);
    const mbps = await execDownloadPlan(response.rawData);
    const stats = {
      jitter: jitter.toFixed(1),
      lag: lag.toFixed(1),
      mbps: mbps.toFixed(1),
    };
    await request.call('submitSpeedTest', {
      body: {
        ...stats,
        origin: {
          pings: durations,
          packages,
        },
      },
    });
    setResult(stats);
    setStatus(READY);
  };

  useEffect(() => {
    check();
  }, []);

  return (
    <DocumentMeta {...meta}>
      <div className="page-check">
        <div className="check-wrapper">
          <If condition={result}>
            <div className="check-result">
              <div className="item">
                <div className="label">
                  <span>下载</span>
                  <small>Mbps</small>
                </div>
                <h2 className="dinpro">{result && result.mbps}</h2>
              </div>
              <div className="item">
                <div className="label">
                  <span>延迟</span>
                  <small>ms</small>
                </div>
                <h2 className="dinpro">{result && result.lag}</h2>
              </div>
              <div className="item">
                <div className="label">
                  <span>抖动</span>
                  <small>ms</small>
                </div>
                <h2 className="dinpro">{result && result.jitter}</h2>
              </div>
            </div>
          </If>

          <div
            onClick={check}
            className={`check-btn ${[LOADING, TESTING].includes(status) &&
              'loading'}`}>
            <div className="inside-circle">
              {' '}
              <span>{btnStatus()}</span>{' '}
            </div>
          </div>

          <div className="check-item">
            <div className="table-cell">
              <div className="left">
                <i className="ri-building-line" />
                <span>服务节点</span>
              </div>
              <div
                className={`right ${egressConnected ? 'success' : ''
                  } ${!egressConnected && 'error'}`}>
                {egressNode}
              </div>
            </div>
            {/* <div className="table-cell">
              <div className="left">
                <i className="ri-earth-line" />
                <span>接入节点</span>
              </div>
              <div className={`right ${ingressConnected ? 'success' : ''}`}>
                {ingressNode}
              </div>
            </div> */}
            <div className="table-cell">
              <div className="left">
                <i className="ri-map-pin-user-line" />
                <span>加速前IP</span>
              </div>
              <div className="right">{ip}</div>
            </div>
            <div className="table-cell">
              <div className="left">
                <i className="ri-account-pin-circle-line" />
                <span>检测账号</span>
              </div>
              <div className="right">{account}</div>
            </div>
            <div className="table-cell">
              <div className="left">
                <i className="ri-map-pin-time-line" />
                <span>测试时间</span>
              </div>
              <div className="right">{time}</div>
            </div>
            <div className="table-cell">
              <div className="left">
                <i className="ri-device-line" />
                <span>设备信息</span>
              </div>
              <div className="right">{device}</div>
            </div>
          </div>
        </div>
      </div>
    </DocumentMeta>
  );
};
export default Check;
