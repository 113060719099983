import React, { useState } from 'react';
import DocumentMeta from 'react-document-meta';

const Center = () => {
  const meta = {
    title: 'VIP权益对比',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };

  const [tab, setTab] = useState(1);

  return (
    <DocumentMeta {...meta}>
      <div className="content-wrapper vip">
        <div className="tab">
          <div
            className={`item-tab ${tab === 1 ? 'active' : ''}`}
            onClick={_ => setTab(1)}>
            <img
              src="https://malus.s3cdn.net/uploads/ic24_free.svg"
              alt="free"
            />
            <span>非会员</span>
          </div>
          <div
            className={`item-tab base ${tab === 2 ? 'active' : ''}`}
            onClick={_ => setTab(2)}>
            <img
              src="https://malus.s3cdn.net/uploads/ic24_base.svg"
              alt="free"
            />
            <span>标准会员</span>
          </div>
          <div
            className={`item-tab game ${tab === 3 ? 'active' : ''}`}
            onClick={_ => setTab(3)}>
            <img
              src="https://malus.s3cdn.net/uploads/ic24_game.svg"
              alt="free"
            />
            <span>高级会员</span>
          </div>
        </div>
        <div className="tab-body">
          {tab === 1 && (
            <div className="content">

              <div className="tr">
                <i className="ri-computer-line" />
                <div className="title">全平台支持</div>
                <div className="right true">
                  <i className="ri-check-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-disc-line" />
                <div className="title">音视频加速</div>
                <div className="right true">
                  <i className="ri-check-line" />
                </div>
              </div>

              <div className="tr">
                <i className="ri-rocket-fill" />
                <div className="title">游戏加速</div>
                <div className="right false">
                  <i className="ri-close-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-parent-fill" />
                <div className="title">影音线路峰值</div>
                <div className="right true">5Mb（SD）</div>
              </div>
              <div className="tr">
                <i className="ri-server-line" />
                <div className="title">游戏专线峰值</div>
                <div className="right true">-</div>
              </div>
              <div className="tr">
                <i className="ri-layout-masonry-line" />
                <div className="title">场景高级优化</div>
                <div className="right true">-</div>
              </div>
              <div className="tr">
                <i className="ri-customer-service-line" />
                <div className="title">客服支持</div>
                <div className="right true">-</div>
              </div>
              <div className="tr">
                <i className="ri-device-line" />
                <div className="title">多设备登录</div>
                <div className="right true">1</div>
              </div>
            </div>
          )}
          {tab === 2 && (
            <div className="content">

              <div className="tr">
                <i className="ri-computer-line" />
                <div className="title">全平台支持</div>
                <div className="right true">
                  <i className="ri-check-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-disc-line" />
                <div className="title">音视频加速</div>
                <div className="right true">
                  <i className="ri-check-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-rocket-fill" />
                <div className="title">游戏加速</div>
                <div className="right false">
                  <i className="ri-close-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-parent-fill" />
                <div className="title">影音线路峰值</div>
                <div className="right true">30Mb（HD）</div>
              </div>
              <div className="tr">
                <i className="ri-server-line" />
                <div className="title">游戏专线峰值</div>
                <div className="right true">-</div>
              </div>
              <div className="tr">
                <i className="ri-layout-masonry-line" />
                <div className="title">场景高级优化</div>
                <div className="right true">-</div>
              </div>
              <div className="tr">
                <i className="ri-customer-service-line" />
                <div className="title">客服支持</div>
                <div className="right true">工单</div>
              </div>
              <div className="tr">
                <i className="ri-device-line" />
                <div className="title">多设备登录</div>
                <div className="right true">3</div>
              </div>
            </div>
          )}
          {tab === 3 && (
            <div className="content">
              {/* <div className="tr">
                <i className="ri-vip-crown-2-line" />
                <div className="title game">包含标准会员全部权益</div>

              </div> */}

              <div className="tr">
                <i className="ri-computer-line" />
                <div className="title">全平台支持</div>
                <div className="right true">
                  <i className="ri-check-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-disc-line" />
                <div className="title">音视频加速</div>
                <div className="right true">
                  <i className="ri-check-line" />
                </div>
              </div>

              <div className="tr">
                <i className="ri-rocket-fill" />
                <div className="title">游戏加速</div>
                <div className="right true">
                  <i className="ri-check-line" />
                </div>
              </div>
              <div className="tr">
                <i className="ri-parent-fill" />
                <div className="title">影音线路峰值</div>
                <div className="right true">50Mb （4K）</div>
              </div>
              <div className="tr">
                <i className="ri-server-line" />
                <div className="title">游戏专线峰值</div>
                <div className="right true">20Mb</div>
              </div>
              <div className="tr">
                <i className="ri-layout-masonry-line" />
                <div className="title">场景高级优化</div>
                <div className="right true">直播、语音、影音</div>
              </div>
              <div className="tr">
                <i className="ri-customer-service-line" />
                <div className="title">客服支持</div>
                <div className="right true">优先</div>
              </div>
              <div className="tr">
                <i className="ri-device-line" />
                <div className="title">多设备登录</div>
                <div className="right true">5</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </DocumentMeta>
  );
};

export default Center;
