import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { useDispatch } from 'redux-react-hook';
import CampaignCenter from './pages/campaign/center';
import CampaignCredits from './pages/campaign/credits';
import CampaignCreditsDetail from './pages/campaign/credits-detail';
import CampaignCheckin from './pages/campaign/checkin';
import CampaignVip from './pages/campaign/vip';
import Support from './pages/support/support';
import SupportCate from './pages/support/support-cate';
import SupportApp from './pages/support/support-app';
import SupportArticle from './pages/support/support-article';
import SupportTicket from './pages/support/support-ticket';
import SupportSuccess from './pages/support/support-success';
import Notification from './pages/notification/notification';
import Check from './pages/speedtest/check';
import Affiliate from './pages/campaign/affiliate';
import Redeem from './pages/campaign/redeem';
import Wechat from './pages/campaign/wechat';

const AuthRoute = props => {
  const dispatch = useDispatch();

  const {
    location: { search },
  } = props;
  const queryParams = new URLSearchParams(search);
  const queryHeaders = {
    'X-Malus-Version': queryParams.get('version'),
    'X-Malus-App': queryParams.get('app'),
    'X-Malus-Token': queryParams.get('token'),
    'X-Malus-UUID': queryParams.get('uuid'),
    'X-Malus-Ch': queryParams.get('ch'),
  };
  const headers = {};
  Object.keys(queryHeaders).forEach(key => {
    if (queryHeaders[key]) {
      headers[key] = queryHeaders[key];
    }
  });
  dispatch({ type: 'headers/set', headers });
  if (queryParams.get('token')) {
    queryParams.delete('token');
    window.history.replaceState(
      null,
      null,
      `${window.location.pathname}?${queryParams.toString()}`,
    );
  }
  return <Route {...props} />;
};

const App = () => (
  <Router>
    <Switch>
      <AuthRoute path="/app" exact component={CampaignCenter} />
      <AuthRoute path="/app/center" exact component={CampaignCenter} />
      <AuthRoute path="/app/credits" exact component={CampaignCredits} />
      <AuthRoute path="/app/checkin" exact component={CampaignCheckin} />
      <AuthRoute path="/app/vip" exact component={CampaignVip} />
      <AuthRoute path="/app/support" exact component={Support} />
      <AuthRoute path="/app/support-cate" exact component={SupportCate} />
      <AuthRoute path="/app/support-app" exact component={SupportApp} />
      <AuthRoute path="/app/support-article" exact component={SupportArticle} />
      <AuthRoute path="/app/support-ticket" exact component={SupportTicket} />
      <AuthRoute path="/app/support-success" exact component={SupportSuccess} />
      <AuthRoute path="/app/notification" exact component={Notification} />
      <AuthRoute path="/app/check" exact component={Check} />
      <AuthRoute path="/app/affiliate" exact component={Affiliate} />
      <AuthRoute path="/app/share" exact component={Affiliate} />
      <AuthRoute path="/app/redeem" exact component={Redeem} />
      <AuthRoute path="/app/wechat" exact component={Wechat} />
      <AuthRoute
        path="/app/credits-detail"
        exact
        component={CampaignCreditsDetail}
      />
    </Switch>
  </Router>
);

export default App;
