import React, { useEffect, useState } from 'react';
import { useDispatch } from 'redux-react-hook';
import DocumentMeta from 'react-document-meta';
import request from '../../shared/request';
import SupportHeader from '../components/support-header';
import SupportFooter from '../components/support-footer';
import native from '../../shared/native';

const SupportCate = props => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [cates, setCates] = useState();
  const getSupportCate = async () => {
    const response = await request.call('getSupportCate');
    if (response.code === 0) {
      setCates(response.data);
    }
    setLoading(false);
  };
  const selectCate = cate => {
    dispatch({ type: 'support/set', cate });
    const query = window.location.search;
    props.history.push(`support-article${query}`);
  };
  useEffect(() => {
    getSupportCate();
  }, []);
  const meta = {
    title: '选择问题类型',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <SupportHeader />
      <div className="support-container support-container-content">
        <div className="navbar">
          <span className="title">请选择反馈问题类型</span>
        </div>
        <div className="content support-cate-content">
          <div className={`cates ${loading ? 'block-loading' : ''}`}>
            <div className="loading">
              <i className="ri-loader-4-line" />
            </div>
            {cates &&
              cates.map(cate => (
                <div className="card" onClick={() => selectCate(cate)}>
                  <span className="circle">
                    <i className={cate.icon} />
                  </span>
                  <div className="right">
                    <span className="title">{cate.title}</span>
                    <span className="desc">{cate.description}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <SupportFooter />
    </DocumentMeta>
  );
};

export default SupportCate;
