import { store } from './store';

// const API_ENDPOINT = 'http://192.168.0.21:5000/api';
// const API_ENDPOINT = 'http://malus.local:5000/api';
// const API_ENDPOINT = 'https://stg-401.getmalus.com/api';
const API_ENDPOINT = 'https://getmalus.net/api';

export default class Request {
  static buildbody(payload, headers) {
    switch (headers['Content-Type']) {
      case 'application/json':
        return JSON.stringify(payload || {});
      case 'multipart/form-data': {
        const formData = new FormData();
        if (payload) {
          Object.keys(payload).forEach((key) => {
            if (key === 'attachments') {
              const files = payload.attachments;
              Object.keys(files).forEach(filename => {
                const blob = new Blob([files[filename]]);
                formData.append('attachments', blob, filename);
              });
            } else {
              formData.append(key, payload[key]);
            }
          });
        }
        delete headers['Content-Type'];
        return formData;
      }
        
      default:
        break;
    }
  }

  static async post(fn, options) {
    let headers = {
      credentials: 'include',
      'Content-Type': 'application/json',
    };
    const state = store.getState();
    try {
      const user = JSON.parse(window.localStorage.getItem('login'));
      if (user && user.token) {
        headers['X-Malus-Token'] = user.token;
      }
    } catch (error) {
      // noop
    }

    if (state.headers) {
      headers = { ...headers, ...state.headers };
    }

    if (options.headers) {
      headers = { ...headers, ...options.headers };
    }

 
    let json = {
      code: 1,
      message: 'Server Error',
    };
    const requestBody = Request.buildbody(options.body, headers);
    try {
      const response = await fetch(
        `${options.API_ENDPOINT ? options.API_ENDPOINT : API_ENDPOINT}/${fn}`,
        {
          method: 'POST',
          headers,
          body: requestBody,
        },
      );
      json = await response.json();
    } catch (err) {
      json = {
        code: 1,
        message: err.message || 'ERROR',
      };
    }
    return json;
  }

  static call(fn, options = {}) {
    return Request.post(fn, options);
  }
}
