import React, { useEffect, useState } from 'react';

import DocumentMeta from 'react-document-meta';
import dayjs from 'dayjs';
import { Toast } from 'antd-mobile';
import Loading from '../components/loading';
import If from '../components/if';
import request from '../../shared/request';

const Page = () => {
  const [redeem, setRedeem] = useState([]);
  const [income, setIncome] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState('income');
  const init = async () => {
    const { code, data } = await request.call('getCreditOverview');
    console.log(data);
    setLoading(true);
    if (code === 0) {
      setRedeem(data.redeem);
      setIncome(data.income);
    } else {
      Toast.fail('数据加载失败 请重试');
    }
  };
  useEffect(() => {
    init();
  }, []);
  const meta = {
    title: '积分明细',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="page-credit-details content-wrapper">
        <div className="tabs">
          <div
            className={`tab ${tab === 'income' ? 'active' : ''}`}
            onClick={_ => setTab('income')}>
            收益明细
          </div>
          <div
            className={`tab ${tab === 'redeem' ? 'active' : ''}`}
            onClick={_ => setTab('redeem')}>
            兑换明细
          </div>
        </div>
        <section className="detail">
          <Loading condition={loading}>
            <If condition={tab === 'income'}>
              <If condition={income.length <= 0}>
                <div className="nodata">
                  <div className="icon"></div>
                  <span>暂无数据</span>
                </div>

              </If>
              {income.map(record => (
                <div className="item item-add">
                  <div className="left">
                    <div className="name">{record.name}</div>
                    <div className="time">
                      {dayjs(record.createdAt).format('MM/DD hh:mm')}
                    </div>
                  </div>
                  <div className="right">
                    <small>+</small>
                    <div className="nb nbfont">{record.points}</div>
                    <i className="ri-copper-coin-fill" />
                  </div>
                </div>
              ))}
            </If>
            <If condition={tab === 'redeem'}>
              <If condition={redeem.length <= 0}>
                <div className="nodata">
                  <div className="icon"></div>
                  <span>暂无数据</span>
                </div>
              </If>
              {redeem.map(record => (
                <div className="item item-subtract">
                  <div className="left">
                    <div className="name">{record.name}</div>
                    <div className="time">
                      {dayjs(record.createdAt).format('MM/DD hh:mm')}
                    </div>
                  </div>
                  <div className="right">
                    <div className="nb nbfont">{record.points}</div>
                    <i className="ri-copper-coin-fill" />
                  </div>
                </div>
              ))}
            </If>
          </Loading>
        </section>
      </div>
    </DocumentMeta>
  );
};

export default Page;
