import React from 'react';
import { ActivityIndicator } from 'antd-mobile';

const Loading = props => {
  const { condition, children } = props;
  if (condition) {
    return children;
  }
  return <ActivityIndicator className="loading" color="white" />;
};

export default Loading;
