import React from 'react';

const BottomSheet = props => (
  <div className={`bottom-sheet ${props.show ? 'show' : ''}`}>
    <div className="mask" onClick={props.close} />
    <div className="body">
      <div className="title">
        <h4>{props.title}</h4>
        <i className="ri-close-line" onClick={props.close} />
      </div>
      <div className="content">{props.children}</div>
    </div>
  </div>
);

export default BottomSheet;
