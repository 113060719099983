import React from 'react';

const SupportHeader = props => (
  <div className="support-footer">
    <div className="support-container footer">
      <div className="left">
        <span>© 2018-2022 Malus. All rights reserved.</span>
      </div>
      <div className="right-nav">
        <a href="https://getmalus.com/support" target="_blank" rel="noreferrer">
          使用帮助
        </a>
        <a
          href="https://getmalus.com/changelog"
          target="_blank"
          rel="noreferrer">
          更新日志
        </a>
        <a href="https://getmalus.com/" target="_blank" rel="noreferrer">
          Malus 官网
        </a>
      </div>
    </div>
  </div>
);

export default SupportHeader;
