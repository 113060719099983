import React, { useEffect, useState } from 'react';
import { Toast } from 'antd-mobile';
import DocumentMeta from 'react-document-meta';
import request from '../../shared/request';
import native from '../../shared/native';
import SupportHeader from '../components/support-header';
import SupportFooter from '../components/support-footer';


const SupportTicket = props => {
  const [subminting, setSubmiting] = useState(false);
  const [apps, setApps] = useState([]);
  const [selected, setSelected] = useState();

  const select = (value) => {
    const app = apps.find(item => item.package === value);
    setSelected(app.package);
  }

  const submit = async () => {
    if (subminting) return;
    setSubmiting(true);
    const app = apps.find(item => item.package === selected);
    if (!app) {
      Toast.info('请选择应用');
      setSubmiting(false);
      return;
    }
    const response = await request.call('createTicket', {
      body: {
        description: `新应用支持申请：${app.name}`,
        name: app.name,
        package: app.package,
      },
    });
    setSubmiting(false);
    if (response.code === 0) {
      return props.history.replace('/app/support-success');
    }
    return Toast.fail(response.message || '反馈失败', 1);
  };

  const ready = async () => {
    const response = await native.getAppList();
    if (Array.isArray(response)) {
      setApps(response);
    }
  }
  useEffect(() => {
    ready();
  }, []);
  const back = () => {
    props.history.replace('/app/support-article');
  };
  const meta = {
    title: '提交应用支持',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <SupportHeader />
      <div className="support-container support-container-content">
        <div className="navbar" onClick={back}>
          <div className="back">
            <i className="ri-arrow-left-circle-line" />
            <span>返回</span>
          </div>
          <span className="title">提交应用支持</span>
        </div>
        <div className="content support-ticket-content">
          <div className="form-apps">

            {apps.map(app => (
              <div className={`app-item ${selected === app.package ? 'active' : ''}`} onClick={() => select(app.package)}>
                <div className="icon-android"><i className="ri-android-line" /></div>
                <span className='app-name'>{app.name}</span>
                <i className="checkbox ri-checkbox-circle-fill" />
              </div>
            ))}

            <button
              onClick={() => submit()}
              className={`${subminting ? 'loading' : ''}`}>
              {subminting ? '提交中...' : '提交'}
            </button>
          </div>

        </div>
      </div>
      <SupportFooter />
    </DocumentMeta>
  );
};

export default SupportTicket;
