import React from 'react';
import { Link } from 'react-router-dom';

const A = props => {
  const { to, children, className } = props;
  return (
    <Link className={className} to={to}>
      {children && children}
    </Link>
  );
};

export default A;
