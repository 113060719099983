import React, { useEffect, useCallback } from 'react';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { Result, Icon } from 'antd-mobile';
import DocumentMeta from 'react-document-meta';
import request from '../../shared/request';
import native from '../../shared/native';

const Center = () => {
  const state = useMappedState(useCallback(root => root), []);
  const dispatch = useDispatch();
  const getActivities = async () => {
    const response = await request.call('getActivities');
    if (response.code === 0) {
      dispatch({ type: 'activities/set', activities: response.data });
    }
  };
  useEffect(() => {
    getActivities();
  }, []);
  const meta = {
    title: '活动中心',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="content-wrapper">
        <NotFound
          condition={!state.activities || state.activities.length === 0}
        />
        {state.activities &&
          state.activities.map(
            activity =>
              activity.type === 'banner' && (
                <img
                  className="banner"
                  src={activity.img}
                  alt="banner"
                  key={activity.img}
                />
              ),
          )}

        <div className="share-content">
          {state.activities &&
            state.activities.map(
              activity =>
                activity.type === 'card' && (
                  <div
                    className="item"
                    key={activity.title}
                    onClick={() => {
                      native.open(activity.link);
                    }}>
                    <img src={activity.img} alt="shareicon" />
                    <div className="content">
                      <div className="title">{activity.title}</div>
                      <span>{activity.desc}</span>
                    </div>
                    <i className="ri-arrow-right-s-line" />
                  </div>
                ),
            )}
        </div>
      </div>
    </DocumentMeta>
  );
};

const NotFound = props => {
  if (props.condition) {
    return (
      <Result
        title=""
        img={<Icon type="ellipsis" size="lg" />}
        message={<div>有一波活动正在路上，请及时关注</div>}
      />
    );
  }
  return <></>;
};

export default Center;
