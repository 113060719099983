function getOS() {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (!os && /Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

function getOSVersion() {
  const os = getOS();
  const ua = window.navigator.userAgent;
  let version = 'Unknown';

  switch (os) {
    case 'Android':
      {
        const splitUserAgent = ua.split('Android ');
        if (splitUserAgent.length > 1) {
          version = splitUserAgent[1].split(';')[0];
        }
      }
      break;
    case 'Windows':
      {
        const splitUserAgent = ua.split('Windows NT ');
        if (splitUserAgent.length > 1) {
          const versionSubStr = splitUserAgent[1];

          if (versionSubStr.startsWith('5.0')) {
            version = '2000';
          } else if (versionSubStr.startsWith('5.1')) {
            version = 'XP';
          } else if (versionSubStr.startsWith('5.2')) {
            version = 'Server';
          } else if (versionSubStr.startsWith('6.0')) {
            version = 'Vista';
          } else if (versionSubStr.startsWith('6.1')) {
            version = '7';
          } else if (versionSubStr.startsWith('6.2')) {
            version = '8';
          } else if (versionSubStr.startsWith('6.3')) {
            version = '8.1';
          } else if (versionSubStr.startsWith('10.0')) {
            version = '10';
          }
        }
      }
      break;
    case 'iOS':
      {
        const splitUserAgent = ua.split('OS ');
        if (splitUserAgent.length > 1) {
          const unformattedVersion = splitUserAgent[1].split(' ')[0];
          version = unformattedVersion.split('_').join('.');
        }
      }
      break;
    case 'Mac':
      {
        const splitUserAgent = ua.split('Mac OS X ');
        if (splitUserAgent.length > 1) {
          let endOfVersion = splitUserAgent[1].indexOf(';');
          const firstParantheses = splitUserAgent[1].indexOf(')');
          if (
            firstParantheses > -1 &&
            (firstParantheses < endOfVersion || endOfVersion == -1)
          ) {
            endOfVersion = firstParantheses;
          }

          const unformattedVersion = splitUserAgent[1].substring(
            0,
            endOfVersion,
          );
          version = unformattedVersion.split('_').join('.');
        }
      }
      break;
    case 'BlackBerry':
      {
        let splitUserAgent = ua.split('BlackBerry9700/');
        if (splitUserAgent.length > 1) {
          version = splitUserAgent[1].split(' ')[0];
        } else {
          splitUserAgent = ua.split('Version/');
          if (splitUserAgent.length > 1) {
            version = splitUserAgent[1].split(' ')[0];
          }
        }
      }
      break;
  }

  return {
    os: os,
    version: version,
  };
}

const stdd = arr => {
  var avg = 0;
  var length = arr.length;
  var len = arr.length;
  var sum = 0;
  for (var i = 0; i < len; i++) {
    sum += arr[i];
  }
  avg = sum / len;
  var temp = [];
  for (var i = 0; i < length; i++) {
    var dev = arr[i] - avg; //计算数组元素与平均值的差
    temp[i] = Math.pow(dev, 2); //计算差的平方
  }
  var powSum = 0; //用来存储差的平方总和
  for (var j = 0; j < temp.length; j++) {
    if (temp[j]) {
      powSum += temp[j]; //计算差的平方总和
    }
  }
  //用差的平方总和除以数组长度即可得到标准差
  return parseFloat(Math.sqrt(powSum / length).toFixed(2));
};

const avg = arr => {
  const sum = arr.reduce((a, b) => a + b, 0);
  return sum / arr.length;
};

const nowTime = () => {
  const d = new Date();
  const year = d.getUTCFullYear();
  const month = d.getUTCMonth() + 1;
  const day = d.getUTCDate();
  const hour = d.getUTCHours() + 8;
  const min = d.getUTCMinutes();
  return `${year}.${month >= 10 ? month : `0${month}`}.${
    day >= 10 ? day : `0${day}`
  } ${hour >= 10 ? hour : `0${hour}`}:${min >= 10 ? min : `0${min}`} (北京)`;
};

const sleep = interval => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, interval);
  });
};

export { getOSVersion, stdd, avg, nowTime, sleep };
