import React from 'react';

const SupportFooter = props => (
  <div className="support-header">
    <div className="support-container header-nav">
      <a className="brand" href="/app/support-cate">
        <img
          src="https://malus.s3cdn.net/uploads/2020-07-23/1595481070705.svg"
          alt="logo"
        />
        <span className="logo">MALUS</span>
        <span className="sub-title">帮助反馈</span>
      </a>
      <div className="nav-link">
        <a
          href="https://malus.zendesk.com/hc/zh-cn/requests"
          className="btn-ticket">
          <i className="ri-file-list-2-line" />
          <span>我的工单</span>
        </a>
        <a className="btn-dashboard" href="https://getmalus.com/dashboard">
          <img
            src="https://malus.s3cdn.net/uploads/ic_support_account.png"
            alt="icon"
          />
          <span>个人中心</span>
        </a>
      </div>
    </div>
  </div>
);

export default SupportFooter;
