import React, { useState, useEffect } from 'react';
import DocumentMeta from 'react-document-meta';
import { Toast } from 'antd-mobile';
import BottomSheet from '../components/bottom-sheet';
import Link from '../components/link';
import Loading from '../components/loading';
import request from '../../shared/request';
import native from '../../shared/native';

let toUpdate = false;

const Center = props => {
  const [show, setShow] = useState(false);
  const [products, setProducts] = useState([]);
  const [overview, setOverview] = useState({});

  const init = async () => {
    const { code, data, message } = await request.call(
      `getCreditGoods?toUpdate=${toUpdate}`,
    );
    if (code === 0) {
      setProducts(data);
    } else {
      Toast.fail(message);
    }
  };

  const initOverview = async () => {
    const { code, data, message } = await request.call('getCreditOverview');
    if (code === 0) {
      setOverview(data);
    } else {
      Toast.fail(message);
    }
  };

  const viewAd = async () => {
    const viewed = await native.viewAd();
    if (viewed) {
      const { code, message } = await request.call('addCredit', {
        body: {
          type: 'viewad',
        },
      });
      if (code === 0) {
        Toast.info('广告积分已添加');
        initOverview();
      } else {
        return Toast.fail(message || '操作异常');
      }
    }
  };

  useEffect(() => {
    init();
    initOverview();
    const search = new URLSearchParams(window.location.search);
    if (search.get('toUpdate')) {
      toUpdate = search.get('toUpdate');
    }
  }, []);
  const meta = {
    title: '积分中心',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <div className="content-wrapper page-credits">
        <BottomSheet title="积分规则" show={show} close={() => setShow(false)}>
          <ol>
            <li>用户需注册后参与积分任务</li>
            <li>每日签到可获得积分，连续签到可得额外奖励</li>
            <li>每次必须完成看完一次广告才可成功领取积分</li>
            <li>
              积分有效期：积分有效期为获取之日起至次年的6月1日。如在2021年12月3日获得的10积分，将在2022年6月1日过期清零。（这里的时间皆为北京时间）
            </li>
            <li>如发现有作弊行为，Malus有权直接取消相应积分。</li>
            <li>本活动解释权归Malus所有。</li>
            <li>所有兑换奖品与活动与苹果公司无关</li>
          </ol>
        </BottomSheet>
        <section className="credit">
          <div
            className="cards"
            onClick={() => props.history.push('/app/credits-detail')}>
            <div className="nb nbfont">
              {overview.total ? overview.total : '0'}
            </div>
            <span>
              我的积分 <i className="ri-arrow-right-s-line" />
            </span>
          </div>
          <div className="cards">
            <div className="nb nbfont">
              {overview.today ? overview.today : '0'}
            </div>
            <span>今日获益</span>
          </div>
        </section>
        <section className="tasks">
          <div className="title">
            <h4>每日积分任务</h4>
            <span onClick={() => setShow(true)}>积分规则</span>
          </div>

          <Link className="task-item banner" to="/app/affiliate">
            <div className="left">
              <h3>邀请好友得现金</h3>
              <div className="des">
                <p>
                  注册成功得 <strong>50积分</strong>
                </p>
                <p>
                  购买成功最高 <strong>返现 100%</strong>
                </p>
              </div>
            </div>
            <div className="right" />
          </Link>

          <div className="task-item">
            <div className="task-left">
              <div className="name">
                邀请好友
                <i className="ri-copper-coin-fill" />
                <strong>50</strong>
                <div className="tag">积分+现金</div>
              </div>
              <div className="desc">好友注册得50积分，购买成功得现金</div>
            </div>
            <Link className="btn btn-default" to="/app/affiliate">
              邀请好友
            </Link>
          </div>

          <div className="task-item">
            <div className="task-left">
              <div className="name">
                每日签到
                <i className="ri-copper-coin-fill" />
                <strong>2</strong>
              </div>
              <div className="desc">连续签到7天，额外得20积分</div>
            </div>
            <Link className="btn btn-default" to="/app/checkin">
              {overview.hasCheckin ? '查看签到' : '每日签到'}
            </Link>
          </div>

          {/* <div className="task-item" onClick={viewAd}>
            <div className="task-left">
              <div className="name">
                观看广告
                <i className="ri-copper-coin-fill" />
                <strong>{overview.viewadCount}</strong>
                <strong>/20</strong>
              </div>
              <div className="desc">每看一次得1积分，单日最高10积分</div>
            </div>
            <span className="btn btn-default">观看广告</span>
          </div> */}

        </section>
        <section className="exchange">
          <div className="title">
            <h4>积分兑换</h4>
            <span onClick={() => props.history.push('/app/credits-detail')}>
              积分明细
            </span>
          </div>
          <Loading condition={products}>
            <div className="exchange-items">
              {products &&
                products.map(v => (
                  <div
                    key={v.id}
                    className="exchange-item"
                    onClick={e => props.history.push(`/app/redeem?id=${v.id}`)}>
                    <img src={v.logo} alt="" />
                    <div className="name">{v.name}</div>
                    <div className="bottom">
                      <i className="ri-copper-diamond-fill" />
                      <span>{v.points} 积分</span>
                    </div>
                  </div>
                ))}
            </div>
          </Loading>
        </section>
      </div>
    </DocumentMeta>
  );
};

export default Center;
