import EventEmiter from 'events';

class WebBridge {
  async shareText(content) {
    console.log(content);
  }

  shareImage(image, scene) {
    console.log({ image, scene });
  }

  canShare() {
    return false;
  }

  isMobile() {
    return window.MalusApp || window.webkit;
  }

  open(url) {
    window.open(url);
  }

  viewAd() {
    console.log('view ad');
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, 1);
    });
  }

  updateUser() {
    console.log('update User info');
  }

  getInfo() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          data: {
            device: 'web',
            mode: 'unknown',
            token: 'empty',
            version: '0.9.9',
            uuid: 'empty',
          },
        });
      }, 1);
    });
  }

  // getAppList() {
  //   return new Promise(resolve => {
  //     setTimeout(() => {
  //       resolve({
  //         data: [
  //           { package: 'com.tencent.mm', name: '微信' },
  //           { package: 'com.tencent.mobileqq', name: 'QQ' },
  //           { package: 'com.eg.android.AlipayGphone', name: '支付宝' },
  //         ],
  //       });
  //     }, 1);
  //   });
  // }


  getAppList() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve([
          { package: 'com.tencent.mm', name: '微信' },
          { package: 'com.tencent.mobileqq', name: 'QQ' },
          { package: 'com.eg.android.AlipayGphone', name: '支付宝' },
        ]);
      }, 1);
    });
  }

  getLog() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          data: {
            url: '--',
          },
        });
      }, 1);
    });
  }

  getAdInfo(type, info, token) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          code: 1,
          message: 'E 请在 Malus 客户端内完成此操作',
        });
      }, 1);
    });
  }

  preloadAd() { }
}
class Api {
  constructor() {
    this.event = new EventEmiter();
    const device = this.device();
    if (['android', 'ios'].includes(device)) {
      window.MalusDispatch = {};
      window.MalusDispatch.adRewardEarned = () => {
        this.event.emit('adViewed');
      };
      window.MalusDispatch.getInfo = info => {
        this.event.emit('getInfo', info);
      };
      window.MalusDispatch.getLog = info => {
        this.event.emit('getLog', info);
      };
      window.parseAdInfoCb = info => {
        this.event.emit('parseAdInfoCb', info);
      };
      window.MalusDispatch.queryAllPackages = info => {
        this.event.emit('queryAllPackages', info);
      };
    }

    window.MalusBridge = {};
    // for windows
    window.MalusBridge.PostResult = info => {
      if (info.type === 'SetAdLocal') {
        this.event.emit('SetAdLocal', info);
      }
    };
    // for mac
    window.MalusBridge.SetAdLocal = info => {
      this.event.emit('SetAdLocal', info);
    };
  }

  isMobile() {
    return window.MalusApp || window.webkit;
  }

  device() {
    if (window.MalusApp) {
      return 'android';
    }
    if (window.webkit && window.webkit.messageHandlers) {
      return 'ios';
    }
    if (window.MalusDispatch && window.MalusDispatch.CloseWindow) {
      return 'windows';
    }
    if (window.MalusDispatch && window.MacGap) {
      return 'mac';
    }
  }

  async getAdInfo(type, info, token) {
    const device = this.device();
    let response;
    switch (device) {
      case 'windows':
        response = await this.getAdInfoForWindows(type, info, token);
        break;
      case 'mac':
        response = await this.getAdInfoForMac(type, info, token);
        break;
      case 'ios':
        response = await this.getAdInfoForiOS(type, info, token);
        break;
      case 'android':
        response = await this.getAdInfoForAndroid(type, info, token);
        break;
      default:
        break;
    }
    if (!response) {
      return {
        code: 1,
        message: '请在 Malus 客户端内完成此操作',
      };
    }
    return {
      code: 0,
      rawData: response,
    };
  }

  getAdInfoForWindows(type, info, token) {
    return new Promise(resolve => {
      this.event.on('SetAdLocal', response => {
        let result = {};
        console.log('into');
        try {
          result = JSON.parse(response.data);
        } catch (e) {
          console.log('eee', e);
        }
        resolve(result);
      });
      window.MalusDispatch.SetAdLocal(token, type, info);
    });
  }

  getAdInfoForMac(type, info, token) {
    return new Promise(resolve => {
      this.event.on('SetAdLocal', response => {
        resolve(response);
      });
      window.MalusDispatch.SetAdLocal(type, info, token);
    });
  }

  getAdInfoForAndroid(type, info, token) {
    window.MalusApp.parseAdInfo(type, info, token, 'parseAdInfoCb');
    return new Promise(resolve => {
      this.event.on('parseAdInfoCb', response => {
        let result = {};
        try {
          result = JSON.parse(response.data);
        } catch (e) { }
        resolve(result);
      });
    });
  }

  getAdInfoForiOS(type, info, token) {
    const parameters = { type, info, token, callback: 'parseAdInfoCb' };
    window.webkit.messageHandlers.parseAdInfo.postMessage(parameters);
    return new Promise(resolve => {
      this.event.on('parseAdInfoCb', response => {
        let result = {};
        try {
          result = JSON.parse(response.data);
        } catch (e) { }
        resolve(result);
      });
    });
  }

  async shareText(content) {
    if (window.MalusApp) {
      window.MalusApp.shareText(content);
    } else {
      window.webkit.messageHandlers.shareText.postMessage(content);
    }
  }

  canShare() {
    if (window.MalusApp) {
      return window.MalusApp.shareImage;
    }
    return window.webkit && window.webkit.messageHandlers.shareImage;
  }

  async shareImage(image, scene) {
    if (window.MalusApp) {
      window.MalusApp.shareImage(image, scene);
    } else {
      window.webkit.messageHandlers.shareImage.postMessage({ image, scene });
    }
  }

  open(url) {
    if (window.MalusApp) {
      window.MalusApp.openWebView(encodeURI(url));
    } else {
      window.webkit.messageHandlers.openWebView.postMessage(encodeURI(url));
    }
  }

  getInfo() {
    if (window.MalusApp) {
      window.MalusApp.getInfo('MalusDispatch.getInfo');
    } else {
      // eslint-disable-next-line no-unused-expressions
      window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.getInfo.postMessage(
          'MalusDispatch.getInfo',
        );
    }
    if (['ios', 'android'].includes(this.device())) {
      return new Promise(resolve => {
        this.event.on('getInfo', response => {
          resolve(response);
        });
      });
    }
  }

  getLog() {
    if (window.MalusApp) {
      window.MalusApp.getLog('MalusDispatch.getLog');
    } else {
      // eslint-disable-next-line no-unused-expressions
      window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.getLog.postMessage(
          'MalusDispatch.getLog',
        );
    }
    if (['ios', 'android'].includes(this.device())) {
      return new Promise(resolve => {
        this.event.on('getLog', response => {
          resolve(response);
        });
      });
    }
  }

  getAppList() {
    if (window.MalusApp) {
      window.MalusApp.queryAllPackages('MalusDispatch.queryAllPackages');
    } else {
      // eslint-disable-next-line no-unused-expressions
      window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.getLog.postMessage(
          'MalusDispatch.queryAllPackages',
        );
    }
    if (['ios', 'android'].includes(this.device())) {
      return new Promise(resolve => {
        this.event.on('queryAllPackages', response => {
          resolve(response);
        });
      });
    }
  }

  updateUser() {
    if (window.MalusApp) {
      window.MalusApp.updateUser();
    } else {
      window.webkit.messageHandlers.updateUser.postMessage(null);
    }
  }

  viewAd() {
    if (window.MalusApp) {
      window.MalusApp.showAd();
    } else {
      window.webkit.messageHandlers.showAd.postMessage(null);
    }
    return new Promise(resolve => {
      this.event.on('adViewed', () => {
        resolve(true);
      });
    });
  }

  preloadAd() {
    if (window.MalusApp) {
      window.MalusApp.preloadAd();
    } else {
      // eslint-disable-next-line no-unused-expressions
      window.webkit &&
        window.webkit.messageHandlers.preloadAd.postMessage(null);
    }
  }
}

const DEV = false;

const native = DEV ? new WebBridge() : new Api();
export default native;
