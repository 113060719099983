import React, { useEffect, useState } from 'react';
import DocumentMeta from 'react-document-meta';
import { Toast } from 'antd-mobile';
import request from '../../shared/request';
import Link from '../components/link';

const Center = () => {
  const meta = {
    title: '每日签到',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };
  const [days, setDays] = useState(new Array(7).fill(0));
  const [hasCheckin, setHasCheckin] = useState(false);
  const [checkinDays, setCheckinDays] = useState('0');

  const init = async () => {
    const { code, data, message } = await request.call('getCreditOverview');
    if (code === 0) {
      const daysArr = new Array(7).fill(0).fill(1, 0, data.checkinDays);
      setDays(daysArr);
      setHasCheckin(!!data.hasCheckin);
      setCheckinDays(data.checkinDays);
    } else {
      Toast.fail(message);
    }
  };
  const checkin = async () => {
    if (hasCheckin) {
      return;
    }
    const { code, data, message } = await request.call('addCredit', {
      body: {
        type: 'checkin',
      },
    });
    if (code === 0) {
      Toast.success('签到成功');
      const daysArr = new Array(7).fill(0).fill(1, 0, data.checkinDays);
      setDays(daysArr);
      setHasCheckin(!!data.hasCheckin);
      setCheckinDays(data.checkinDays);
    } else {
      Toast.fail(message);
    }
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <DocumentMeta {...meta}>
      <div className="content-wrapper page-checkin">
        <div className="header">
          <div className="title">天天签到，兑换VIP时长免费用</div>
          <div className="desc">连续签到 7 天，可以额外获赠 20 积分</div>
        </div>
        <div className="banner" />
        <div className="box">
          <div className="head">
            <div className="title">
              本周期已签到 <span className="gold">{checkinDays}</span> 天
            </div>
            <Link className="more" to="/app/credits-detail">
              <i className="ri-file-list-line" />
              <span>积分明细</span>
            </Link>
          </div>
          <div className="box-content">
            {days.map((v, index) => (
              <div className={v ? 'day active' : 'day'}>
                <span>
                  第{index + 1}天{index >= 6 ? ' - 连续签到额外得 20 积分' : ''}
                </span>
                <i
                  className={
                    v ? 'ri-checkbox-circle-line' : 'ri-copper-diamond-line'
                  }
                />
              </div>
            ))}
          </div>
          <button onClick={checkin} className={hasCheckin ? 'disabled' : ''}>
            签到领积分
          </button>
        </div>
        <div className="rule-content">
          <span className="title">签到规则</span>
          <ul>
            <li className="rule">每日签到得 2 积分。</li>
            <li className="rule">连续签到 7 天可额外获得 20 积分奖励</li>
            <li className="rule">每个签到周期为 7 天，每期结束将重新计算。</li>
            <li className="rule">
              如果发现作弊现象，Malus 有权取消活动资格及收回相关奖励
            </li>
          </ul>
        </div>
      </div>
    </DocumentMeta>
  );
};

export default Center;
