import React, { useCallback, useEffect, useState } from 'react';
import { useMappedState } from 'redux-react-hook';
import DocumentMeta from 'react-document-meta';
import request from '../../shared/request';
import native from '../../shared/native';
import If from '../components/if';
import SupportHeader from '../components/support-header';
import SupportFooter from '../components/support-footer';

const SupportArticle = props => {
  const [loading, setLoading] = useState(true);
  const {
    support: { title, cid },
  } = useMappedState(useCallback(state => state, []));
  const [articles, setArticles] = useState([]);
  const getSupportArticle = async () => {
    const response = await request.call('getSupportArticle', { body: { cid } });
    if (response.code === 0) {
      setArticles(response.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    getSupportArticle();
  }, []);
  const back = () => {
    const query = window.location.search;
    props.history.replace(`/app/support-cate${query}`);
  };

  const meta = {
    title,
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      <SupportHeader />
      <div className="support-container support-container-content">
        <div className="navbar" onClick={back}>
          <div className="back">
            <i className="ri-arrow-left-circle-line" />
            <span>返回</span>
          </div>
          <span className="title">{title}</span>
        </div>
        <div className="content support-article-content">
          <div className={`articles ${loading ? 'block-loading' : ''}`}>
            <div className="title">常见问题</div>
            <div className="loading">
              <i className="ri-loader-4-line" />
            </div>
            {articles &&
              articles.map(article => (
                <div
                  className="article"
                  onClick={() => native.open(`${article.url}`)}>
                  <i className="ri-file-list-2-line icon-doc" />
                  <span>{article.title}</span>
                  <i className="ri-arrow-right-s-line icon-arrow" />
                </div>
              ))}
            <If condition={!loading && articles.length < 1}>
              <div>
                <h3>暂无常见问题解决方案</h3>
              </div>
            </If>
            <button
              onClick={() =>
                props.history.replace(
                  `/app/support-ticket${window.location.search}`,
                )
              }>
              提交反馈
            </button>
          </div>
        </div>
      </div>
      <SupportFooter />
    </DocumentMeta>
  );
};

export default SupportArticle;
