import React, { useEffect, useState } from 'react';
import { Toast } from 'antd-mobile';
import DocumentMeta from 'react-document-meta';
import request from '../../shared/request';
import native from '../../shared/native';
import If from '../components/if';
import SupportHeader from '../components/support-header';
import SupportFooter from '../components/support-footer';

let requiredValues = [];

const FormGroup = (props) => {
  const { item, updateData, data } = props;
  const { type, name, placeholder, label, required, options, sub } = item;
  useEffect(() => {
    // default set
    if (item.default) {
      const value = options.find(v => v === item.default);
      if (value) {
        updateData(name, value);
      }
    }
    // set required
    if (required) {
      requiredValues.push(name);
    }
    return () => {
      // cancel required
      requiredValues = requiredValues.filter(v => v !== name);
    };
  }, []);
  switch (type) {
    case 'select':
      return (
        <React.Fragment>
          <div className="form-group">
            <label>{label}</label>
            <select
              onChange={e => updateData(name, e.target.value)}
              value={data[name]}
            >
              <option disabled selected>
                {placeholder}
              </option>
              {options.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <i className="ri-arrow-down-s-line" />
          </div>
          {sub &&
            sub[data[name]] &&
            sub[data[name]].map((v) => (
              <FormGroup
                key={v.name}
                item={v}
                data={data}
                updateData={updateData}
              />
            ))}
        </React.Fragment>
      );
    case 'text':
      return (
        <div className="form-group">
          <label>{label}</label>
          <input
            key={name}
            type="text"
            value={data[name]}
            onChange={e => updateData(name, e.target.value)}
            placeholder={placeholder}
          />
        </div>
      );
    case 'textarea':
      return (
        <div className="form-group">
          <label>{label}</label>
          <textarea
            key={name}
            name={name}
            value={data[name]}
            onChange={e => updateData(name, e.target.value)}
            placeholder={placeholder}
          />
        </div>
      );
    default:
      return <></>;
  }
};

const SupportTicket = (props) => {
  const [loading, setLoading] = useState(true);
  const [scheme, setScheme] = useState([]);
  const [data, setData] = useState({});
  const updateForm = (k, value) => {
    setData({ ...data, [k]: value });
  };

  const [subminting, setSubmiting] = useState(false);

  const submit = async () => {
    if (subminting) {
      return Toast.info('正在反馈中...', 1);
    }
    const empty = requiredValues.find(v => !data[v]);
    if (empty) {
      return Toast.fail(`请填写必填选项`);
    }
    setSubmiting(true);
    let body = { ...data };
    if (files.length > 0) {
      const attachments = {}
      files.forEach(file => {
        attachments[file.name] = file;
      })
      body = { ...body, attachments };
    }
    const log = await native.getLog();
    if (log.data && log.data.url) {
      body = { ...body, log: log.data.url };
    }
    const info = await native.getInfo();
    if (info && info.data) {
      body = { ...body, ...info.data };
    }
    if (info.error) {
      setSubmiting(false);
      return Toast.fail(info.error || '信息获取异常', 1);
    }

    const response = await request.call('createTicket', {
      headers: { 'Content-Type': 'multipart/form-data' },
      body
    });
    setSubmiting(false);
    if (response.code === 0) {
      return props.history.replace('/app/support-success');
    }
    return Toast.fail(response.message || '反馈失败', 1);
  };
  const [files, setFiles] = useState([]);
  const loadFile = (event) => {
    const filelist = event.target.files;
    const arr = Array.from(filelist);
    if (arr) {
      setFiles([...files, ...arr]);
    }
  };

  const removeFile = (file) => {
    const arr = files.filter(v => v !== file);
    setFiles(arr);
  };

  const init = async () => {
    setLoading(true);
    const response = await request.call('getSupportScheme', {});
    setLoading(false);
    if (response.code !== 0) {
      return Toast.fail(response.message || '获取数据失败', 1);
    }
    setScheme(response.data);
  };
  useEffect(() => {
    init();
  }, []);
  const meta = {
    title: '提交反馈',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <SupportHeader />
      <div className="support-container support-container-content">
        <div className="navbar">
          <span className="title">提交反馈</span>
        </div>
        <div className="content support-ticket-content">
          <div className={`form ${loading ? 'block-loading' : ''}`}>
            <div className="loading">
              <i className="ri-loader-4-line" />
            </div>
            {scheme.map((item) => (
              <FormGroup
                key={item.name}
                item={item}
                data={data}
                updateData={updateForm}
              />
            ))}
            <div className="right-form">
              {!loading && (
                <div className="form-group">
                  <label>上传附件</label>
                  <div className="upload">
                    <input
                      name="userfile"
                      type="file"
                      multiple
                      onChange={loadFile}
                    />
                    <i className="ri-upload-cloud-line" />
                    <span>点击添加附件</span>
                  </div>

                  {files &&
                    files.map(file => (
                      <div className="upload-file-list">
                        <span className="file-name">{file.name}</span>
                        <span
                          className="file-del"
                          onClick={() => removeFile(file)}
                        >
                          移除
                        </span>
                      </div>
                    ))}
                </div>
              )}
            </div>
            <If condition={!loading}>
              <button
                onClick={() => submit()}
                className={`${subminting ? 'loading' : ''}`}>
                {subminting ? '反馈中...' : '发送反馈'}
              </button>
            </If>
          </div>
        </div>
      </div>
      <SupportFooter />
    </DocumentMeta>
  );
};

export default SupportTicket;
