import React from 'react';
import DocumentMeta from 'react-document-meta';
import SupportHeader from '../components/support-header';
import SupportFooter from '../components/support-footer';

const SupportSuccess = props => {
  const back = () => {
    props.history.replace('/app/support-cate');
  };
  const meta = {
    title: '反馈成功',
    description: '',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: '',
      },
    },
  };
  return (
    <DocumentMeta {...meta}>
      <SupportHeader />
      <div className="support-container support-container-content">
        <div className="content success-content">
          <img
            src="https://malus.s3cdn.net/uploads/support-success-icon.svg"
            alt="icon"
          />
          <h3>问题上报成功</h3>
          <span>
            你的问题已经成功上报，我们将及时与你取得联系，请留意您的邮箱及工单状态
          </span>
          <button className="btn-back" onClick={back}>
            查看常见问题
          </button>
        </div>
      </div>
      <SupportFooter />
    </DocumentMeta>
  );
};

export default SupportSuccess;
